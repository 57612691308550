import React, { useState } from "react";

const YorumListesi = (props) => {
    const [comment, setComment] = useState(null);

    const editComment = (yorum) =>setComment(yorum.id)
    const closeComment =()=>setComment(null)

    const result=(yorum)=>{
        if(comment===yorum.id){
            return <div className="ui input"><input type="text" value={yorum.body} /></div>
        }
        else{
            return <div className="description">{yorum.body}</div>
        }
        setComment(null)
    }
    
    return (
        <React.Fragment>
            <h3>Yorumlar</h3>
           
            {props.yorumlar.map(yorum => {
                return (
                    <div key={yorum.id} className="ui relaxed list">
                        <div className="item">
                            <div className="content">
                                <div className="ui grid">
                                    <div className="row">
                                        <div className="fourteen wide column">
                                            <span className="header">{yorum.display_name}</span>
                                            {result(yorum)}
                                        </div>
                                        <div className="two wide column">
                                            <i onClick={() => { editComment(yorum) }} className="edit icon"></i>
                                            <i onClick={() => { closeComment(yorum) }} className="delete icon"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
            

        </React.Fragment>
    )
}

export default YorumListesi;    