import React, { useState } from "react"
import { Button, Modal } from 'semantic-ui-react'
import { api } from "../api";
import { withRouter } from "react-router-dom"

const SilModal=(props)=>{
    const [open,setOpepn]=useState(false);

    const show=()=>{
        setOpepn(true)
    }

    const close=()=>{
        setOpepn(false)
    }

    const handleDelete=()=>{
        api().delete(`/posts/${props.yazi.id}`)
        .then(response=>{
            //response=>console.log(response)
            props.history.push('/')
        })
    }
    return <React.Fragment>
        <Button color="red" onClick={show}>Sil</Button>
        <Modal size="mini" open={open} onClose={close} >
            <Modal.Header>
                Başlık
            </Modal.Header>
            <Modal.Content>
                Silmek istiyor musunuz ?
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={close}>İptal Et</Button>
                <Button onClick={handleDelete} positive icon="delete" labelPosition="right" content="Evet, sil"/>
            </Modal.Actions>
        </Modal>
    </React.Fragment>

}

export default withRouter(SilModal);