import React from "react"
import YaziFormu from "./YaziFormu"

const YaziEkle=()=>{
    return (
        <div>
            <h1>Yazı Ekle</h1>
            <YaziFormu></YaziFormu>
        </div>
    )

}

export default YaziEkle;